<script>
import debounce from 'lodash-es/debounce'
import api from '/~/core/api'
import ui from '/~/core/ui'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import Popup from '/~/components/base/select/popup'

const GIPHY_KEY = 'ThtpUoZF8rn5bCDgDbpK6DQuwZ74R2mV'

export default {
  name: 'base-gifs',
  components: {
    Popup,
    BaseInput,
    BaseLoader,
  },
  props: {
    rating: {
      type: String,
      default: 'G',
      validator: (v) => !v || /G|PG|PG-13|R/.test(v),
    },
    limit: {
      type: Number,
      default: 20,
    },
  },
  setup() {
    return {
      ui,
    }
  },
  data() {
    return {
      loading: false,
      visible: false,
      search: '',
      gifs: [],
      popupRootElem: null,
    }
  },
  methods: {
    onGifSelect(gif) {
      this.$emit('select', {
        name: gif.title,
        cdn: gif.images.downsized.url || gif.images.original_still.url,
        type: 'image/gif',
      })
      this.close()
    },
    show() {
      this.popupRootElem = ui.mobile ? this.$parent.$el : null
      this.visible = true

      if (!this.gifs.length) this.loadTrendingGifs()
    },
    close() {
      this.visible = false
      this.search = ''
    },
    async fetch(point = 'trending', q = '') {
      try {
        this.loading = true

        const { data } = await api.get(
          `https://api.giphy.com/v1/gifs/${point}?api_key=${GIPHY_KEY}&limit=${this.limit}&rating=${this.rating}${q}`
        )

        return data
      } catch (error) {
        console.error('base-gifs', error)
        return []
      } finally {
        this.loading = false
      }
    },
    async loadTrendingGifs() {
      this.gifs = await this.fetch('trending')
    },
    loadGifs: debounce(async function () {
      const query = this.search.trim().toLowerCase()

      if (!query || query.length < 2) return
      this.gifs = await this.fetch('search', `&q=${query}`)
    }, 400),
  },
}
</script>

<template>
  <div class="relative">
    <popup
      :visible="visible"
      :fullwidth="ui.mobile"
      :rel-element="popupRootElem"
      @click="close"
    >
      <div
        class="relative mr-6 flex h-96 w-full flex-col overflow-hidden rounded-md bg-white shadow-lg sm:w-72"
      >
        <base-loader v-if="loading" class="absolute inset-0 bg-white-overlay" />

        <div class="mt-2.5 px-2.5">
          <base-input
            v-model="search"
            placeholder="Search for a GIF..."
            clearable
            nolabel
            @input="loadGifs"
          />
        </div>

        <div v-if="gifs" class="flex flex-1 flex-wrap overflow-auto px-[5px]">
          <div
            v-for="gif in gifs"
            :key="gif.id"
            class="h-16 w-1/4 cursor-pointer rounded-md p-[5px] hover:opacity-75"
            @click="onGifSelect(gif)"
          >
            <img
              :src="
                gif.images.preview_gif.url ||
                gif.images.downsized.url ||
                gif.images.original_still.url
              "
              :alt="gif.title"
              class="h-full w-full rounded-md bg-divider-light object-cover"
            />
          </div>
        </div>
        <div
          v-else
          class="flex flex-1 items-center justify-center text-fg-disabled"
        >
          Not found
        </div>
      </div>
    </popup>
  </div>
</template>
